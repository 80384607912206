import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/uniswap-org-v1/src/layouts/faq.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">Didn't find an answer? Explore the <a href="https://help.uniswap.org/">help guides</a> or join the <a href="https://discord.gg/FCfyBSbCU5">community Discord</a> to get support</Info>
    <h1 {...{
      "id": "what-is-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#what-is-uniswap",
        "aria-label": "what is uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is Uniswap?`}</h1>
    <p>{`Uniswap is a protocol for creating liquidity and trading ERC-20 tokens on `}<a parentName="p" {...{
        "href": "https://ethereum.org/en/what-is-ethereum/"
      }}>{`Ethereum`}</a>{`. It eliminates trusted intermediaries and unnecessary forms of rent extraction, allowing for fast, efficient trading. Where it makes tradeoffs - decentralization, censorship resistance and security are prioritized. Uniswap is open-source software licensed under GPL.`}</p>
    <p>{`If you want to dive into details check out the `}<a parentName="p" {...{
        "href": "/docs/v2/"
      }}>{`docs`}</a>{`.`}</p>
    <h1 {...{
      "id": "how-do-i-use-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-use-uniswap",
        "aria-label": "how do i use uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I use Uniswap?`}</h1>
    <p>{`First you’ll need an `}<a parentName="p" {...{
        "href": "https://ethereum.org/en/wallets/"
      }}>{`Ethereum Wallet`}</a>{` and some `}<a parentName="p" {...{
        "href": "https://ethereum.org/en/get-eth/"
      }}>{`ETH`}</a>{`. Once completed, head over to the `}<a parentName="p" {...{
        "href": "http://app.uniswap.org/"
      }}>{`app`}</a>{` to start using the protocol to provide liquidity or swap tokens. Remember that each transaction on Ethereum costs ETH (this is called the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=AJvzNICwcwc&feature=emb_title"
      }}>{`“gas fee”`}</a>{` and it’s paid to miners to keep the network running).`}</p>
    <h1 {...{
      "id": "how-does-uniswap-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-does-uniswap-work",
        "aria-label": "how does uniswap work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does Uniswap work?`}</h1>
    <p>{`Uniswap is an `}<a parentName="p" {...{
        "href": "https://ethereum.org/en/get-eth/#dex"
      }}>{`automated liquidity protocol`}</a>{`. In practical terms this means there are template smart contracts that define a standard way to make liquidity pools and corresponding markets that are compatible with each other. There is no orderbook, no centralized party and no central facilitator of trade. Each pool is defined by a smart contract that includes a few functions to enable swapping tokens, adding liquidity and more. At its core each pool uses the function `}<inlineCode parentName="p">{`x*y=k`}</inlineCode>{` to maintain a curve along which trades can happen. The pools keep track of reserves(liquidity) and updates those reserves every single time someone trades. Because the reserves are automatically rebalanced, a Uniswap pool can always be used to buy or sell a token without requiring a counterparty on the other side of a trade.`}</p>
    <p>{`For a more in-depth description. Check out the `}<a parentName="p" {...{
        "href": "/docs/v2/protocol-overview/how-uniswap-works/"
      }}>{`How Uniswap works`}</a>{` from the documentation.`}</p>
    <h1 {...{
      "id": "how-are-prices-determined",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-are-prices-determined",
        "aria-label": "how are prices determined permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How are prices determined?`}</h1>
    <p>{`Prices are determined by the amount of each token in a pool. The smart contract maintains a constant using the following function: `}<inlineCode parentName="p">{`x*y=k`}</inlineCode>{`. In this case `}<inlineCode parentName="p">{`x = token0`}</inlineCode>{`, `}<inlineCode parentName="p">{`y = token1`}</inlineCode>{`, `}<inlineCode parentName="p">{`k = constant`}</inlineCode>{`. During each trade a certain amount of one token is removed from the pool for an amount of the other token. To maintain `}<inlineCode parentName="p">{`k`}</inlineCode>{`, the balances held by the smart contract are adjusted during the execution of the trade, therefore changing the price.`}</p>
    <h1 {...{
      "id": "i-saw-a-token-was-trading-at-x-value-but-when-i-traded-on-uniswap-i-got-a-much-lower-value",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#i-saw-a-token-was-trading-at-x-value-but-when-i-traded-on-uniswap-i-got-a-much-lower-value",
        "aria-label": "i saw a token was trading at x value but when i traded on uniswap i got a much lower value permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I saw a token was trading at X value, but when I traded on Uniswap I got a much lower value!`}</h1>
    <p>{`Websites that show token prices, like eitherscan.io or coinmarketcap.com, typically aggregate price data from multiple sources. Some of these sources can be inaccurate, especially regarding new or low volume tokens. If a website or app says a token is worth more than what is shown on Uniswap, it is due to the website’s or app’s inaccurate data sources. Always check the exchange rate on the Uniswap app before trading.`}</p>
    <h1 {...{
      "id": "why-is-my-swap-failing-or-stuck",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-is-my-swap-failing-or-stuck",
        "aria-label": "why is my swap failing or stuck permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why is my swap failing or stuck?`}</h1>
    <p>{`You might be trying to swap a fee on transfer or deflationary token. If so, you have to increase your allowed slippage to account for the fee taken during the swap. Click on the gear for settings and adjust slippage tolerance accordingly.`}</p>
    <p>{`Otherwise, if your transaction is taking forever or stuck pending, the gas included might be too low and the transaction will not be processed. You probably need to speed up or cancel the transaction in you wallet.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Fix stuck transactions in MetaMask: `}<a parentName="p" {...{
            "href": "https://metamask.zendesk.com/hc/en-us/articles/360015489251-How-to-Speed-Up-or-Cancel-a-Pending-Transaction"
          }}>{`https://metamask.zendesk.com/hc/en-us/articles/360015489251-How-to-Speed-Up-or-Cancel-a-Pending-Transaction`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fix stuck transactions in Trust wallet: `}<a parentName="p" {...{
            "href": "https://www.publish0x.com/the-crypt/quick-guide-to-fixing-stuck-ethereum-transactions-xgdvgkv"
          }}>{`https://www.publish0x.com/the-crypt/quick-guide-to-fixing-stuck-ethereum-transactions-xgdvgkv`}</a></p>
      </li>
    </ul>
    <h1 {...{
      "id": "why-is-my-approve-stuck",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-is-my-approve-stuck",
        "aria-label": "why is my approve stuck permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why is my approve stuck?`}</h1>
    <p>{`If you speed up an approve, Uniswap can’t detect the new transaction. Open your wallet in the interface and click “clear all” then try refreshing the page.`}</p>
    <h1 {...{
      "id": "how-do-i-find-x-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-find-x-token",
        "aria-label": "how do i find x token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I find X token?`}</h1>
    <p>{`If you can’t find a token in the default list, find the token address using etherscan and paste that address into the search field.`}</p>
    <h1 {...{
      "id": "how-do-i-add-a-logo-for-a-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-add-a-logo-for-a-token",
        "aria-label": "how do i add a logo for a token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I add a logo for a token?`}</h1>
    <p>{`Uniswap pulls from the trustwallet asset repository on github. `}<a parentName="p" {...{
        "href": "https://github.com/trustwallet/assets"
      }}>{`https://github.com/trustwallet/assets`}</a>{` Add your token icon to that repo and it will appear on the frontend and on info.`}</p>
    <h1 {...{
      "id": "how-can-i-add-a-token-to-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-can-i-add-a-token-to-uniswap",
        "aria-label": "how can i add a token to uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I add a token to Uniswap?`}</h1>
    <p>{`Uniswap is compatible with any ERC-20 token in the Ethereum ecosystem. If you want your project to be searchable in their interface, you should seek to be added to a reputable token list or share a link to your token using query parameters. Once loaded via link, the token will be added to their interface.`}</p>
    <p>{`Another option is to open a request using github `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-frontend/issues/new?assignees=&labels=token+request&template=token-request.md&title="
      }}>{`issues`}</a>{`.`}</p>
    <p>{`The Uniswap team makes no guarantees nor provides any timeline for such requests. Nor will the team ever charge or request funds. We’ve added many UX features to make it easier to share a new token with communities - features such as local storage support and custom linking. Please make use of them.`}</p>
    <h1 {...{
      "id": "why-does-my-transaction-cost-x",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-does-my-transaction-cost-x",
        "aria-label": "why does my transaction cost x permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why does my transaction cost X?`}</h1>
    <p>{`Ethereum requires gas to execute each transaction. You can also check ETH gas station for the current prices required to complete transactions. Creating a Uniswap pool is a slightly costlier transaction because you are executing a more complex smart contract. Read more about how gas works in Ethereum.`}</p>
    <h1 {...{
      "id": "how-can-i-see-my-liquidity-provider-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-can-i-see-my-liquidity-provider-fees",
        "aria-label": "how can i see my liquidity provider fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I see my liquidity provider fees?`}</h1>
    <p>{`There are many community built tools like `}<a parentName="p" {...{
        "href": "https://www.uniswaproi.com/"
      }}>{`https://www.uniswaproi.com/`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.zapper.fi/dashboard"
      }}>{`https://www.zapper.fi/dashboard`}</a>{` and `}<a parentName="p" {...{
        "href": "https://zerion.io/"
      }}>{`https://zerion.io/`}</a>{`. Uniswap provides no guarantees the information on these sites is correct.`}</p>
    <h1 {...{
      "id": "i-accidentally-transferred-tokens-directly-to-a-pair-contract-is-there-any-way-i-can-get-them-back",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#i-accidentally-transferred-tokens-directly-to-a-pair-contract-is-there-any-way-i-can-get-them-back",
        "aria-label": "i accidentally transferred tokens directly to a pair contract is there any way i can get them back permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I accidentally transferred tokens directly to a pair contract, is there any way I can get them back?`}</h1>
    <p>{`Uniswap token pair contracts are ownerless. We have no special control over the pair contracts’ functionality; because of this, any tokens accidentally transferred to the token contract itself are lost forever.`}</p>
    <h1 {...{
      "id": "i-updated-my-token-via-a-proxy-contract-but-the-uniswap-information-page-is-showing-the-old-symbol--name",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#i-updated-my-token-via-a-proxy-contract-but-the-uniswap-information-page-is-showing-the-old-symbol--name",
        "aria-label": "i updated my token via a proxy contract but the uniswap information page is showing the old symbol  name permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I updated my token via a proxy contract, but the Uniswap information page is showing the old symbol / name!`}</h1>
    <p><a parentName="p" {...{
        "href": "https://info.uniswap.org/"
      }}>{`Info.uniswap`}</a>{` reads the details of new tokens when they’re added to the subgraph. There may be some circumstances where an old symbol or name isn’t updated if the token was updated with a proxy contract. A guide to fix this yourself is forthcoming. In the meantime, reach out with the details on our developer discord.`}</p>
    <h1 {...{
      "id": "my-swap-failed-with-code-expired-what-happened",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#my-swap-failed-with-code-expired-what-happened",
        "aria-label": "my swap failed with code expired what happened permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My swap failed with code “EXPIRED”, what happened?`}</h1>
    <p>{`If a swap takes more than 20 minutes to execute, the router is programmed to fail the transaction. This is to protect the user from extreme swings in prices that can occur while the transaction is pending. If this happens, your tokens will still be in your wallet, but the gas fees paid are not recoverable. To keep this from happening, use a high enough gas price to have your transaction mined in under 20 minutes. This usually falls under “Standard” or “Fast” in most gas price calculators.`}</p>
    <h1 {...{
      "id": "uniswap-tools-and-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uniswap-tools-and-resources",
        "aria-label": "uniswap tools and resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap tools and resources`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/Uniswap/universe"
      }}>{`https://github.com/Uniswap/universe`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      